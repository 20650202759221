@import "./assets/fonts/index.css";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "~nprogress/nprogress.css";
@import "~react-toastify/dist/ReactToastify.css";

.d-flex > br {
  display: none;
}

@font-face {
  font-family: "PlusJakartaSans";
  src: url(./PlusJakartaSans-ExtraLight.ttf) format('truetype');;
  font-weight: 200;
}

@font-face {
  font-family: "PlusJakartaSans";
  src: url(./PlusJakartaSans-Light.ttf) format('truetype');;
  font-weight: 300;
}

@font-face {
  font-family: "PlusJakartaSans";
  src: url(./PlusJakartaSans-Regular.ttf) format('truetype');;
  font-weight: 400;
}

@font-face {
  font-family: "PlusJakartaSans";
  src: url(./PlusJakartaSans-Medium.ttf) format('truetype');;
  font-weight: 500;
}

@font-face {
  font-family: "PlusJakartaSans";
  src: url(./PlusJakartaSans-SemiBold.ttf) format('truetype');;
  font-weight: 600;
}

@font-face {
  font-family: "PlusJakartaSans";
  src: url(./PlusJakartaSans-Bold.ttf) format('truetype');;
  font-weight: 700;
}

@font-face {
  font-family: "PlusJakartaSans";
  src: url(./PlusJakartaSans-ExtraBold.ttf) format('truetype');;
  font-weight: 800;
}

